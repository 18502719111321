exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-departments-tsx": () => import("./../../../src/pages/departments.tsx" /* webpackChunkName: "component---src-pages-departments-tsx" */),
  "component---src-pages-facilities-tsx": () => import("./../../../src/pages/facilities.tsx" /* webpackChunkName: "component---src-pages-facilities-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ourjyothisfamily-tsx": () => import("./../../../src/pages/ourjyothisfamily.tsx" /* webpackChunkName: "component---src-pages-ourjyothisfamily-tsx" */),
  "component---src-pages-supportus-tsx": () => import("./../../../src/pages/supportus.tsx" /* webpackChunkName: "component---src-pages-supportus-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

